// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Utilities } from '../app/utils/utilities';


const config = window.__config || {
	useFingerprintPro: false,
	fingerprintProKey: "",
	fpEndpoint:"",
	daysForDuidRefresh: -1,
	reconfirmContactTimeout: 5,
	reconfirmEmailPattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
	rvidTimeout: 2,
	sampleChainTimeout: 2,
	baseApi: "https://api-surveys.sample-cube.com/api/v1",
	rfgTimeout: 2,
	rfgLib: "//hosting.researchforgood.com/js/df-fp.js",
};

export const environment = {
	fingerprintProKey: {
		val: config.fingerprintProKey
	},
	useFingerprintPro: {
		val: config.useFingerprintPro
	},
	fpEndpoint: {
		val: config.fpEndpoint
	},
	daysForDuidRefresh: {
		val: config.daysForDuidRefresh
	},
	production: true,
	reconfirmContact: {
		apiGetUrl: "https://api.panel-cube.com/api/user/v2/paymentaddress?TokenId={token}",
		apiUpdateUrl: "https://api.panel-cube.com/api/user/v2/updatepaymentaddress",
		timeout: config.reconfirmContactTimeout || 5,
		cache: true,
		emailPattern:"^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
	},
	rvid: {
		key: "9D424814-4C14-4196-A5F9-3B388AD98779",
		lib: "https://d3op16id4dloxg.cloudfront.net/RelevantID4.js",
		timeout: config.rvidTimeout || 2,
		cache: true
	},

	sampleChain: {
		key: "9ed35863-39a4-41b0-a30e-0de31b4e672b",
		privateKey:"607b1eca-a3e5-4808-ad3e-565fbf6e0ba5",
		lib: "//prod.rtymgt.com/static/js/993xddk3/scdvcCapture-v1-1.min.js?ver=20240912.2",
		apiUrl: "https://prod.rtymgt.com/api/v4/respondents/",
		timeout: config.sampleChainTimeout || 2,
		cache: true
	},
	coreSurveyDropCookie: {
		lib: "//gwiqcdn.globalwebindex.net/gwiq/gwiq.js",
		timeout: 2
	},
	rfgFingerPrint: {
		lib: config.rfgLib || "//survey.saysoforgood.com/utils/rfg-fp.js",
		timeout:config.rfgTimeout || 2,
		cache: true
	},
	comScore: {
		lib: config.comScoreLib || "//sb.scorecardresearch.com/p?c1=14&c2=37581797&c4=s&cj=1&c3=",
		timeout:config.comScoreTimeout || 2,
		cache: true
	},
	baseApi: config.baseApi || "https://api-surveys.sample-cube.com/api/v1",
	appInsights: {
		instrumentationKey: "306a2f62-2435-4455-a53f-db84c3bb1a7f",
		disableExceptionTracking: false,
		disableAjaxTracking: false
	},
	buildNumber: "20240912.2",
	envName: "{EnvName}",
	mbdPixelUrl:
		"https://secure.insightexpressai.com/adserver/PanelistPartnerESI.aspx?PartnerID=10069&Iso={country}&PanelistID={userid}&redir=https%3A%2F%2Fsample-cube.com",
	linkedInAuthUrl:
		"https://externalauth.sample-cube.com/Linkedin/CheeseCakeAuth"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
